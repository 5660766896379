@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Kanit:wght@300;500;600&display=swap');

$colorBlack: #000000;
$colorWhite: #ffffff;
$colorLink: #797979;
$colorFontHeader: #47404f;
$colorAlert: #2962ff;
$colorRed: #dc3545;
$colorYellow: #ffc107;
$colorBackground1: #F3F6F9;
$colorBackground2: #1D1D42;
$colorAuxiliary1: #2962ff;
$colorAuxiliary1Light: #2962ff69;
$boxShadow: 0 0 10px rgb(0 0 0 / 8%);
$boxShadowCard: 0 11px 10px rgb(0 0 0 / 8%);
;
$borderColor: #ebebee;
$colorGreen: #28a745;

$colorContent1: #7266ba;
$colorContent2: #42a5f6;
$colorContent3: #7ed320;
$colorContent4: #f75d81;
$colorContent5: #f7c45d;
