@import '../../mixins.scss';

.navbar-e{
    min-height: 60px;
    padding: inherit !important;
    background-color: $colorWhite;
    color: $colorLink;
    box-shadow: $boxShadow;

    position: fixed !important;
    width: 100%;
    top: 0;
    z-index: 100;
}

.container-e{

    @media screen and (min-width: 200px){
        max-width: 95% !important;;
    }

}

.notifications{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 10px;
    color: $colorLink;
    &-div{
        position: relative;
        i{
            cursor: pointer;
        }

    }

    &-alert{
        position: absolute;
        top:-10px;
        left:10px;
        color: $colorAlert;
    }
    & .span-notification-msg{
        position: absolute;
        top: 26px;
        font-size: 0.8rem;
        min-width: 260px;
        // padding-top: 0;
        // padding-bottom: 0;
        text-align: center;
        left: -222px;
        padding: 0;
        border: 1px solid #00000026 !important;

        overflow: auto;
        max-height: 168px;
        display: block;
        & .navLinkNotification{
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 7px ;

            span.title{
                font-size: 0.9rem;
            }
            span.time{
                font-size: 0.7rem;
            }
        }
    }
    &:active{
        color: $colorBlack;
    }
}

span{
    padding-right: 0.3rem;
}

.i-e{
    position: relative;;
    i{
        color: $colorBlack;
        position: absolute;
        top: -15px;
        left: 0;
        font-size: 2rem ;
        opacity: 100;
        transition: opacity 500ms;

    }
    &:hover{
        cursor: pointer;
    }
}

.nav-link-e-profile{
    border: none !important;
    & .profile-option{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        &-icon{
            color: $colorLink;
            i{
                font-size: 1.1rem;

            }

        }
        &-title{
            color: $colorLink;
            font-size: 0.9rem;

        }
    }
}

.dropdown-toggle{
    &::after{
        content: none !important;
    }
}


.main-section{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: 60px;
    min-height: calc(100vh - 60px);
    padding: 30px 30px 0 250px;
    transition: padding 1s;
    @media screen and (max-width: 1000px) {
        padding-left: 30px;
        
    }
    &-hide{
        @extend .main-section;
        padding-left: 30px;
    }
    
}

.opacity0{
    opacity: 0% !important;
    transition: opacity 500ms !important;
}
.opacity100{
    opacity: 100% !important;
    transition: opacity 500ms !important;
}


.img{
    display: flex;
    justify-content: center;
    border: 1px solid $colorBlack;
    width: 50%;
    text-align: -webkit-center;
    &-perfil{
        width: 100%;

        &-navbar{
            width: 30px;
            height: 30px;
            border-radius: 100%;
            aspect-ratio: 3/2;
            object-fit: contain;
            border: 0.1px solid rgba(0, 0, 0, 0.39);

        }
    }
}

.logo{
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.modal-messages{
    position: fixed;
    right: 10px;
    top: 10px;
    width: 350px;
    max-height: 100%;
    overflow: hidden auto;
    z-index: 1000;
    
}

.hidden-notification{
    animation: hiddenCard 0.5s linear;
}

@keyframes hiddenCard {
    from{
        right: 10px;
    }
    to{
        right: -500px;
    }
}

.countdown-timer-alert{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: transparent;
    animation: countdown 10.8s linear;
    
}

@keyframes countdown {
    from{
        background-color: #008037;
        width: 100%;
    }
    to{
        background-color: #008037;
        width: 0%;
    }
}